import ForbesLogo from '../../assets/logos/forbes-logo.png'
import MetroLogo from '../../assets/logos/metro-logo.png'
import eveningStandardLogo from '../../assets/logos/evening-standard-logo.png'
import HealthAndWellbeingLogo from '../../assets/logos/health-and-wellbeing-logo.png'
import TechroundLogo from '../../assets/logos/techround-logo.png'
import TopSanteLogo from '../../assets/logos/top-sante-logo.png'
import WomensFitnessLogo from '../../assets/logos/womens-fitness-logo.png'
import SustainHealthLogo from '../../assets/logos/sustain-health-logo.png'
import BalanceLogo from '../../assets/logos/balance-logo.png'
import StylusLogo from '../../assets/logos/stylus-logo.png'
import MarieClaireLogo from '../../assets/logos/marie-claire-logo.png'

export const features = [
  { name: 'Forbes',
    source: ForbesLogo,
    style: { flexShrink: 0, width: 83, height: 20 },
  },
  {
    name: 'Metro',
    source: MetroLogo,
    style: { flexShrink: 0, width: 97, height: 20 },
  },
  /*{
    name: 'Evening Standard',
    source: eveningStandardLogo,
    style: { flexShrink: 0, width: 217, height: 28 },
  }, */
  {
    name: 'Health and Wellbeaing',
    source: HealthAndWellbeingLogo,
    style: { flexShrink: 0, width: 85, height: 38 },
  },
  {
    name: 'Techround',
    source: TechroundLogo,
    style: { flexShrink: 0, width: 119, height: 28 },
  },
  {
    name: 'Top Sante',
    source: TopSanteLogo,
    style: { flexShrink: 0, width: 80, height: 38 },
  },
  {
    name: 'Womens Fitness',
    source: WomensFitnessLogo,
    style: { flexShrink: 0, width: 97, height: 32 },
  },
  {
    name: 'Sustain Health',
    source: SustainHealthLogo,
    style: { flexShrink: 0, width: 269, height: 26 },
  },
  {
    name: 'Balance',
    source: BalanceLogo,
    style: { flexShrink: 0, width: 119, height: 36 },
  },
  {
    name: 'Stylus',
    source: StylusLogo,
    style: { flexShrink: 0, width: 102, height: 48 },
  },
  {
    name: 'Marie Claire',
    source: MarieClaireLogo,
    style: { flexShrink: 0, width: 174, height: 38, alignSelf: 'flex-start' },
  },
]
