import { React, StyleSheet, View, Text, CenterWrapper, Image } from 'lib'
import { Theme } from 'app'
import { features } from './_features'

const Featured: React.FC = () => {
  return (
    <>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
        <Text variant={`h6 greyscale4`} text={`As Featured In`}/>
        <View style={styles.companyLogosWrapper}>
          {features.map((image, index) => (
            <Image
              key={index}
              source={image.source}
              alt={`${image.name} logo`}
              style={image.style}
            />
          ))}
        </View>
      </CenterWrapper>
    </>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    backgroundColor: Theme.colors.greyscale1,
    position: 'relative',
  },
  innerWrapper: {
    marginTop: Theme.spacing(7),
    marginBottom: Theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
    gap: Theme.spacing(4),
    ...Theme.justifyCenter,
    ...Theme.alignCenter,
  },
  companyLogosWrapper: {
    display: 'flex',
    ...Theme.justifyCenter,
    ...Theme.alignCenter,
    gap: Theme.spacing(5),
    flexWrap: 'wrap',
  },
})

export default Featured
